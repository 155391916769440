import { Button, Form, Input, Space, Typography, message } from 'antd';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { formValidatorRules } from '../../../common/utils';
import './forgot-page.less';

export function ForgotPage() {
  const [form] = Form.useForm();
  const { required, email } = formValidatorRules;

  const auth = getAuth();

  const [loading, setLoading] = useState(false);

  const sendPassword = async (formValue) => {
    // eslint-disable-next-line no-console
    setLoading(true);

    await sendPasswordResetEmail(auth, formValue.email)
      .then(() => {
        message.success('Link sent to your email');
      })
      .catch((error) => {
        if (error) {
          message.error('User not found');
        }
      });
    setLoading(false);
  };

  return (
    <div className="forgot-pass-page-content">
      <div className="forgot-pass-container">
        <Space size={15} align="vertical">
          <span className="forgot-pass-title">
            <Typography.Title>FORGOT PASSWORD</Typography.Title>
          </span>
          <span className="forgot-pass-sub-title">
            <Typography.Text>
              We’ll send a recovery link via registered email address{' '}
            </Typography.Text>
          </span>
        </Space>

        <div className="forgot-pass-form">
          <Form form={form} onFinish={sendPassword} layout="vertical">
            <Form.Item
              label="Email Address"
              name="email"
              rules={[required, email]}
            >
              <Input type="email" placeholder="Enter your email address" />
            </Form.Item>
            <Button
              loading={loading}
              htmlType="submit"
              className="forgot-pass-btn"
            >
              SEND LINK
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
