/* eslint-disable no-console */
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EyeIcon } from '../../assets/ui-icons/eye-icon.svg';
import SearchComponent from '../../common/components/SearchDebounce';
import { formValidatorRules } from '../../common/utils';

import {
  FREQUENCY,
  MACHINE_TYPES,
  TABLE_PAGINATION_LIMIT
} from '../../common/constants';
import { getLocationById } from '../locations/locations-repository';
import { getAdvisorById } from '../technical-advisors/advisors-repository';
import {
  createMachine,
  getAdvisorsList,
  getLocationList,
  getMachines
} from './machines-repository';

function Machines() {
  // ALL DECLARATIONS GOES HERE
  const { required } = formValidatorRules;
  const [createForm] = Form.useForm();
  const limit = TABLE_PAGINATION_LIMIT;
  const navigate = useNavigate();

  const pageChange = (ID) => {
    const url = `${ID}`;
    navigate(url);
  };

  // STATES GOES HERE
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [machineList, setMachineList] = useState([]);
  const [isLoadBtn, setIsLoadBtn] = useState(false);
  const [nextAfter, setNextAfter] = useState(null);
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [advisorOptions, setAdvisorOptions] = useState([]);

  const fetchLocations = async () => {
    const locations = await getLocationList();
    if (locations) {
      const formattedOptions = locations.map((location) => ({
        value: location.id,
        label: location.name
      }));
      setOptions(formattedOptions);
    }
  };

  const fetchAdvisors = async () => {
    const advisors = await getAdvisorsList();
    if (advisors) {
      const formattedOptions = advisors.map((advisor) => ({
        value: advisor.id,
        label: advisor.name
      }));
      setAdvisorOptions(formattedOptions);
    }
  };

  // ALL METHODS & FUNCTIONS GOES HERE
  const getMachineList = async (searchQuery = query, reset = false) => {
    setIsLoading(true);
    const startAfter = reset ? null : nextAfter;
    try {
      const response = await getMachines(startAfter, limit, searchQuery);
      const newData = response?.machines || [];

      setMachineList((prevList) =>
        reset ? [...newData] : [...prevList, ...newData]
      );

      setIsLoadBtn(response?.machines?.length === limit);
      setNextAfter(response?.nextStartAfter);

      setIsLoading(false);
    } catch (error) {
      message.error('Something went wrong');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMachineList();
    fetchLocations();
    fetchAdvisors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelCreate = () => {
    // createForm.resetFields();
    setIsCreateModal(false);
  };

  const disabledDate = (current) => {
    return current && current < moment().endOf('day');
  };

  const onSubmitCreateForm = async (formValues) => {
    const {
      machineName,
      machineId,
      machineType,
      inspector,
      location,
      inspectionFrequency,
      date
    } = formValues;
    const payload = {
      name: machineName,
      machineId: machineId,
      type: machineType,
      locationId: location,
      inspectionFrequency: inspectionFrequency,
      inspectorId: inspector,
      selectDate: dayjs(date).format('YYYY-MM-DD')
    };
    setIsSavingData(true);
    try {
      const newMachine = await createMachine(payload);
      const advisor = await getAdvisorById(newMachine.inspectorId);
      const locationDetails = await getLocationById(newMachine.locationId);
      // Add advisor and locationDetails to newMachine object
      newMachine.advisor = advisor;
      newMachine.location = locationDetails;

      // Update machineList state with newMachine
      setMachineList((prevList) => [...prevList, newMachine]);
      createForm.resetFields();
      setIsCreateModal(false);
    } catch (error) {
      //
    } finally {
      setIsSavingData(false);
    }
  };

  // const deleteAdmin = async (adminId) => {
  //   setIsLoading(true);
  //   try {
  //     // Delete admin by ID
  //     await deleteAdminById(adminId);
  //     setAdminList((prevAdminList) =>
  //       prevAdminList.filter((admin) => admin.adminId !== adminId)
  //     );

  //     setIsLoading(false);
  //     message.success('Admin deleted ');
  //   } catch (error) {
  //     //
  //     setIsLoading(false);
  //   }
  // };

  // TABLE DECLARATIONS GOES HERE
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'MACHINE NAME',
      dataIndex: 'name',
      key: '2'
    },
    {
      title: 'MACHINE ID',
      dataIndex: 'machineId',
      key: '3'
    },
    {
      title: 'MACHINE TYPE ',
      dataIndex: 'type',
      key: '4'
      // render: (value) => {
      //   const formattedDate = formatTimestamp(value, customFormat);
      //   return formattedDate;
      // }
    },
    {
      title: 'LOCATION  ',
      dataIndex: 'location',
      key: '5',
      render: (value) => {
        return value?.name || 'NA';
      }
    },
    {
      title: 'INSPECTOR  ',
      dataIndex: 'id',
      key: '6',
      render: (value, record) => {
        return record?.advisor?.name;
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: '7',
      render: (value) => {
        return (
          <div className="table-col-btn">
            <EyeIcon className="btns" onClick={() => pageChange(value)} />
          </div>
        );
      }
    }
  ];

  return (
    <div className="main-container">
      <Header className="main-header">
        <Typography.Title className="header-title">MACHINES</Typography.Title>
        <Button onClick={() => setIsCreateModal(true)}>ADD NEW</Button>
      </Header>

      <div className="sub-container">
        <div className="top-bar">
          <SearchComponent
            name="Machines"
            debounceTime={300} // Consider increasing debounce time to prevent too rapid API calls
            getData={(value) => {
              setQuery(value); // Update search query
              setNextAfter(null); // Reset pagination
              setMachineList([]); // Clear existing list to load new results
              getMachineList(value, true); // Pass query and a reset flag to the fetching function
            }}
          />
        </div>
        <div className="content-table">
          <Table
            columns={columns}
            dataSource={machineList}
            loading={isLoading}
            pagination={false}
            rowKey="adminId"
          />
          {isLoadBtn ? (
            <Button
              onClick={() => getMachineList()}
              className="secondary-btn load-btn"
            >
              Load more
            </Button>
          ) : null}
        </div>
      </div>

      {/* ALL MODAL GOES HERE */}
      {/* CREATE MODAL */}
      <Modal
        title="NEW MACHINE"
        open={isCreateModal}
        onCancel={handleCancelCreate}
        footer={null}
        className="admin-modal"
        centered
      >
        <Divider />
        <div className="modal-content">
          <Form
            layout="vertical"
            form={createForm}
            onFinish={onSubmitCreateForm}
          >
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  rules={[required]}
                  label="Machine name"
                  name="machineName"
                >
                  <Input
                    type="text"
                    className="modal-inp"
                    placeholder="Enter machine name "
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[required]}
                  label="Machine ID"
                  name="machineId"
                >
                  <Input
                    type="text"
                    className="modal-inp"
                    placeholder="Enter machine id "
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  rules={[required]}
                  label="Machine type"
                  name="machineType"
                >
                  <Select
                    showSearch
                    placeholder="Select machine type"
                    options={MACHINE_TYPES}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[required]}
                  label="Inspector"
                  name="inspector"
                >
                  <Select
                    showSearch
                    placeholder="Select inspector"
                    options={advisorOptions}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item rules={[required]} label="Location" name="location">
              <Select
                showSearch
                options={options}
                placeholder="Select location"
              />
            </Form.Item>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  rules={[required]}
                  label="Inspection Frequency"
                  name="inspectionFrequency"
                >
                  <Select
                    placeholder="Select inspection frequency"
                    options={FREQUENCY}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item rules={[required]} label="Select Date" name="date">
                  <DatePicker disabledDate={disabledDate} />
                </Form.Item>
              </Col>
            </Row>

            <Button
              loading={isSavingData}
              className="modal-btn"
              htmlType="submit"
            >
              ADD NEW MACHINE
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default Machines;
