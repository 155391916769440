import { message } from 'antd';
import api from '../../common/api';
import { API_ROUTES } from '../../common/constants';

export const getALlLocations = async (startAfter, limit, query) => {
  let url = `${API_ROUTES.ALL_LOCATIONS}?startAfter=&limit=${limit}&search=${query}`;
  if (startAfter) {
    url = `${API_ROUTES.ALL_LOCATIONS}?startAfter=${startAfter}&limit=${limit}&search=${query}`;
  }
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: url,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data;
  } catch (error) {
    return null;
  }
};

export const createLocation = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'post',
      url: API_ROUTES.CREATE_LOCATION,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      },
      data: data
    });
    return response?.data?.data?.createdLocation;
  } catch (error) {
    return null;
  }
};

export const updateLocation = async (data, ID) => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'put',
      url: `${API_ROUTES.UPDATE_LOCATION}?locationId=${ID}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      },
      data: data
    });

    return response?.data?.data?.updatedLocation;
  } catch (error) {
    return null;
  }
};

export const getLocationById = async (ID) => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: `${API_ROUTES.SINGLE_LOCATION}?locationId=${ID}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data?.location;
  } catch (error) {
    throw Error(error);
  }
};
export const deleteLocation = async (ID) => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'delete',
      url: `${API_ROUTES.DELETE_LOCATION}?locationId=${ID}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    message.success('Location Deleted');
    return response.data;
  } catch (error) {
    message.error('Error while deleting');
    //
  }
};

export const getMachinesByLocationId = async (ID, limit, startAfter) => {
  let url = `${API_ROUTES.LOCATION_MACHINES}?locationId=${ID}&limit=${limit}`;
  if (startAfter) {
    url = `${API_ROUTES.LOCATION_MACHINES}?locationId=${ID}&limit=${limit}&startAfter=${startAfter}`;
  }
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: url,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data;
  } catch (error) {
    throw Error(error);
  }
};
