/* eslint-disable no-console */
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Table,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchComponent from '../../common/components/SearchDebounce';
import {
  ROUTES,
  TABLE_PAGINATION_LIMIT,
  customFormat
} from '../../common/constants';
import { formValidatorRules, formatTimestamp } from '../../common/utils';
import { getInspections } from './inspection-repository';

function InspectionOversight() {
  // ALL DECLARATIONS GOES HERE
  const { required, email: emailValidator } = formValidatorRules;
  const [createForm] = Form.useForm();
  const limit = TABLE_PAGINATION_LIMIT;
  const navigate = useNavigate();

  // STATES GOES HERE
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inspectionList, setInspectionList] = useState([]);
  const [isLoadBtn, setIsLoadBtn] = useState(false);
  const [nextAfter, setNextAfter] = useState(null);
  const [query, setQuery] = useState('');

  // ALL METHODS & FUNCTIONS GOES HERE
  const getInspectionList = async (searchQuery = query, reset = false) => {
    setIsLoading(true);
    const startAfter = reset ? null : nextAfter;
    try {
      const response = await getInspections(startAfter, limit, searchQuery);
      const newData = response?.inspections || [];

      setInspectionList((prevList) =>
        reset ? [...newData] : [...prevList, ...newData]
      );

      setIsLoadBtn(response?.inspections?.length === limit);
      setNextAfter(response?.nextStartAfter);

      setIsLoading(false);
    } catch (error) {
      message.error('Something went wrong');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInspectionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelCreate = () => {
    // createForm.resetFields();
    setIsCreateModal(false);
  };

  // const onSubmitCreateForm = async (formValues) => {
  //   if (formValues.password.length < 8) {
  //     message.error('Minimum password should be 8 characters');
  //     return;
  //   }
  //   setIsSavingData(true);
  //   try {
  //     const newAdmin = await createAdmin(formValues);
  //     setAdminList((prevAdminList) => [...prevAdminList, newAdmin]);
  //     createForm.resetFields();
  //     setIsCreateModal(false);
  //   } catch (error) {
  //     //
  //   } finally {
  //     setIsSavingData(false);
  //   }
  // };

  // const deleteAdmin = async (adminId) => {
  //   setIsLoading(true);
  //   try {
  //     // Delete admin by ID
  //     await deleteAdminById(adminId);
  //     setAdminList((prevAdminList) =>
  //       prevAdminList.filter((admin) => admin.adminId !== adminId)
  //     );

  //     setIsLoading(false);
  //     message.success('Admin deleted ');
  //   } catch (error) {
  //     //
  //     setIsLoading(false);
  //   }
  // };

  const changePage = (ID) => {
    navigate(`${ROUTES.INSPECTION_OVERSIGHT}/${ID}`);
  };
  // TABLE DECLARATIONS GOES HERE
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'MACHINE NAME',
      dataIndex: 'machine',
      key: '2',
      render: (value) => {
        return value?.name;
      }
    },
    {
      title: 'MACHINE ID',
      dataIndex: 'machine',
      key: '3',
      render: (value) => {
        return value?.machineId;
      }
    },
    {
      title: 'DATE & TIME',
      dataIndex: 'dueDate',
      key: '4',
      render: (value) => {
        const formattedDate = formatTimestamp(value, customFormat);
        return formattedDate;
      }
    },
    {
      title: 'INSPECTOR ',
      dataIndex: 'advisor',
      key: '5',
      render: (value) => {
        return value?.name;
      }
    },
    {
      title: 'LOCATED AT  ',
      dataIndex: 'location',
      key: '6',
      render: (value) => {
        return value?.name;
      }
    },
    {
      title: 'CHECKLIST POINT  ',
      dataIndex: 'checklistCount',
      key: '7',
      render: (value) => {
        return `${value}/10`;
      }
    },

    {
      title: '',
      dataIndex: 'id',
      key: '8',
      render: (value) => {
        return (
          <div className="table-col-btn">
            <Button
              onClick={() => changePage(value)}
              className="primary-btn"
              style={{
                width: '120px',
                height: '40px'
              }}
            >
              Review
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <div className="main-container">
      <Header className="main-header">
        <Typography.Title className="header-title">
          INSPECTION OVERSIGHT
        </Typography.Title>
      </Header>

      <div className="sub-container">
        <div className="top-bar">
          <SearchComponent
            name="Inspections"
            debounceTime={300} // Consider increasing debounce time to prevent too rapid API calls
            getData={(value) => {
              setQuery(value); // Update search query
              setNextAfter(null); // Reset pagination
              setInspectionList([]); // Clear existing list to load new results
              getInspectionList(value, true); // Pass query and a reset flag to the fetching function
            }}
          />
        </div>
        <div className="content-table">
          <Table
            columns={columns}
            dataSource={inspectionList}
            loading={isLoading}
            pagination={false}
            rowKey="adminId"
          />
          {isLoadBtn ? (
            <Button
              onClick={() => getInspectionList()}
              className="secondary-btn load-btn"
            >
              Load more
            </Button>
          ) : null}
        </div>
      </div>

      {/* ALL MODAL GOES HERE */}
      {/* CREATE MODAL */}
      <Modal
        title="NEW ADMIN"
        open={isCreateModal}
        onCancel={handleCancelCreate}
        footer={null}
        className="admin-modal"
        centered
      >
        <Divider />
        <div className="modal-content">
          <Form
            layout="vertical"
            form={createForm}
            // onFinish={onSubmitCreateForm}
          >
            <Form.Item rules={[required]} label="Admin Name" name="adminName">
              <Input
                type="name"
                className="modal-inp"
                placeholder="Enter admin name"
              />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[required, emailValidator]}
            >
              <Input
                className="modal-inp"
                type="email"
                placeholder="Enter email address"
              />
            </Form.Item>
            <Form.Item label="Password" name="password" rules={[required]}>
              <Input.Password
                className="modal-inp"
                placeholder="Enter new password"
              />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              dependencies={['password']} // Add dependency on password field to re-validate when password changes
              rules={[
                required,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!'
                      )
                    );
                  }
                })
              ]}
            >
              <Input.Password
                className="modal-inp"
                placeholder="Confirm your password"
              />
            </Form.Item>
            <Button
              // loading={isSavingData}
              className="modal-btn"
              htmlType="submit"
            >
              ADD NEW ADMIN
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default InspectionOversight;
