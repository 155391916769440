import api from './api';
import { API_ROUTES } from './constants';

export const uploadImage = async (fileName) => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'post',
      url: API_ROUTES.UPLOAD_IMAGE,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      },
      data: { fileName: fileName }
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getFileSignedUrl = async (getUrl) => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'post',
      url: `${API_ROUTES.FILE_SIGNED_URL}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      },
      data: { filePath: getUrl }
    });
    return response.data?.data?.signedUrl;
  } catch (error) {
    return null;
  }
};
