import api from '../../common/api';
import { API_ROUTES } from '../../common/constants';

export const getInspections = async (startAfter, limit, query) => {
  let url = `${API_ROUTES.ALL_INSPECTIONS}?startAfter=&limit=${limit}&search=${query}`;
  if (startAfter) {
    url = `${API_ROUTES.ALL_INSPECTIONS}?startAfter=${startAfter}&limit=${limit}&search=${query}`;
  }
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: url,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data;
  } catch (error) {
    return null;
  }
};

export const getInspectionById = async (inspectionId) => {
  const url = `${API_ROUTES.SINGLE_INSPECTION}?inspectionId=${inspectionId}`;

  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: url,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data;
  } catch (error) {
    return null;
  }
};
