import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import InspectionDetails from '../modules/inspection-oversight/InspectionDetails';
import InspectionOversight from '../modules/inspection-oversight/InspectionOversight';
import LocationDetails from '../modules/locations/LocationDetails';
import Locations from '../modules/locations/Locations';
import MachineDetails from '../modules/machines/MachineDetails';
import Machines from '../modules/machines/Machines';
import TechnicalAdvisors from '../modules/technical-advisors/TechnicalAdvisors';

// eslint-disable-next-line no-unused-vars
function ContentRoutes({ user }) {
  return (
    <Routes>
      <Route exact path={ROUTES.MAIN} element={<Locations user={user} />} />
      <Route
        exact
        path={ROUTES.LOCATION_DETAILS}
        element={<LocationDetails user={user} />}
      />
      <Route
        exact
        path={ROUTES.TECHNICAL_ADVISORS}
        element={<TechnicalAdvisors user={user} />}
      />
      <Route exact path={ROUTES.MACHINES} element={<Machines user={user} />} />
      <Route
        exact
        path={ROUTES.MACHINE_DETAILS}
        element={<MachineDetails user={user} />}
      />
      <Route
        exact
        path={ROUTES.INSPECTION_OVERSIGHT_DETAILS}
        element={<InspectionDetails user={user} />}
      />
      <Route
        exact
        path={ROUTES.INSPECTION_OVERSIGHT}
        element={<InspectionOversight user={user} />}
      />
    </Routes>
  );
}

export default ContentRoutes;
