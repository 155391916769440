import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Table,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as BackBtn } from '../../assets/ui-icons/back-btn-icon.svg';
import { ReactComponent as EyeIcon } from '../../assets/ui-icons/eye-icon.svg';
import { HISTORY_PAGINATION_LIMIT } from '../../common/constants';
import {
  getLocationById,
  getMachinesByLocationId
} from './locations-repository';

function LocationDetails() {
  const { locationId } = useParams();
  const [createForm] = Form.useForm();
  const navigate = useNavigate();
  const limit = HISTORY_PAGINATION_LIMIT;

  // STATES
  const [loadingData, setLoadingData] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [locationName, setLocationName] = useState('LOCATION');
  const [isLoadBtn, setIsLoadBtn] = useState(false);
  const [nextAfter, setNextAfter] = useState(null);
  const [machineList, setMachineList] = useState([]);

  // LOCATION DETAILS
  const fetchLocationDetails = async () => {
    try {
      setLoadingData(true);
      const response = await getLocationById(locationId);
      if (response) {
        setLocationName(response.name);
        createForm.setFieldsValue({
          locationName: response.name,
          city: response.city,
          state: response.state,
          pincode: response.pinCode,
          addressLine: response.address
        });
      }
      setLoadingData(false);
    } catch (error) {
      setLoadingData(true);
      message.error('Failed to fetch location data');
    }
  };

  const fetchMachinesByLocation = async (reset = false) => {
    setTableLoading(true);
    const startAfter = reset ? null : nextAfter;
    try {
      const response = await getMachinesByLocationId(
        locationId,
        limit,
        startAfter
      );
      const newData = response?.machines || [];

      setMachineList((prevList) =>
        reset ? [...newData] : [...prevList, ...newData]
      );

      setIsLoadBtn(response?.machines?.length === limit);
      setNextAfter(response?.nextStartAfter);

      setTableLoading(false);
    } catch (error) {
      message.error('Failed to load machines');
      setTableLoading(false);
    }
  };

  useEffect(() => {
    fetchLocationDetails();
    fetchMachinesByLocation(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageChange = (ID) => {
    const url = `/machines/${ID}`;
    navigate(url);
  };

  // TABLE DECLARATIONS GOES HERE
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'MACHINE NAME',
      dataIndex: 'name',
      key: '2'
    },
    {
      title: 'MACHINE ID',
      dataIndex: 'machineId',
      key: '3'
    },
    {
      title: 'MACHINE TYPE ',
      dataIndex: 'type',
      key: '4'
      // render: (value) => {
      //   const formattedDate = formatTimestamp(value, customFormat);
      //   return formattedDate;
      // }
    },

    {
      title: 'INSPECTOR  ',
      dataIndex: 'id',
      key: '6',
      render: (value, record) => {
        return record?.advisor?.name;
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: '7',
      render: (value) => {
        return (
          <div className="table-col-btn">
            <EyeIcon className="btns" onClick={() => pageChange(value)} />
          </div>
        );
      }
    }
  ];

  return (
    <div className="main-container">
      <Header className="main-header">
        <div className="btn-group">
          <BackBtn className="back-btn" onClick={() => navigate(-1)} />
          <Typography.Title className="header-title">
            {locationName}
          </Typography.Title>
        </div>
      </Header>
      {loadingData ? (
        <Spin
          size="large"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        />
      ) : (
        <div className="sub-container">
          <div className="details-info-form modal-content">
            <Form className="machine-form" layout="vertical" form={createForm}>
              <Row gutter={10}>
                <Col span={6}>
                  <Form.Item label="Location Name" name="locationName">
                    <Input
                      disabled
                      type="text"
                      className="modal-inp disabled-inp"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="City" name="city">
                    <Input
                      type="text"
                      disabled
                      className="modal-inp disabled-inp"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="State" name="state">
                    <Input
                      type="text"
                      disabled
                      className="modal-inp disabled-inp"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Pincode " name="pincode">
                    <Input
                      type="text"
                      disabled
                      className="modal-inp disabled-inp"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item label="Address Line " name="addressLine">
                    <Input
                      type="text"
                      disabled
                      className="modal-inp disabled-inp"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <div className="content-table">
              <Table
                columns={columns}
                dataSource={machineList}
                loading={tableLoading}
                pagination={false}
                rowKey="adminId"
              />
              {isLoadBtn ? (
                <Button
                  onClick={() => fetchMachinesByLocation()}
                  className="secondary-btn load-btn"
                >
                  Load more
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {/* ALL MODAL GOES HERE */}
      {/* CREATE MODAL */}
    </div>
  );
}

export default LocationDetails;
