import { Menu, Popconfirm, Space, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './sidebar.less';

// SIDEBAR LOGO AS REACT COMPONENT (SVG)
import { ReactComponent as InspectionIcon } from '../../assets/sidebar-icons/inspection-icon.svg';
import { ReactComponent as LocationIcon } from '../../assets/sidebar-icons/location-icon.svg';
import { ReactComponent as MachinesIcon } from '../../assets/sidebar-icons/machines-icon.svg';
import { ReactComponent as SignOutsIcon } from '../../assets/sidebar-icons/signout-icon.svg';
import { ReactComponent as TechnicalAdvisorIcon } from '../../assets/sidebar-icons/technical-icon.svg';
import { signOutAdmin } from '../../modules/auth/auth-repository';

export function AppSidebar({ user }) {
  const navigate = useNavigate();

  return (
    <div className="app-sidebar">
      <div className="sidebar-logo">
        <Typography.Title>ADMIN</Typography.Title>
      </div>
      <Menu
        className="sidebar-menu"
        onClick={(item) => {
          navigate(item.key);
        }}
        // eslint-disable-next-line no-undef
        defaultSelectedKeys={[window.location.pathname]}
        // eslint-disable-next-line no-undef
        selectedKeys={[window.location.pathname]}
        mode="inline"
        items={[
          {
            label: 'Locations',
            key: '/',
            icon: <LocationIcon />
          },
          {
            label: 'Technical Advisors',
            key: '/technical-advisors',
            icon: <TechnicalAdvisorIcon />
          },
          {
            label: 'Machines',
            key: '/machines',
            icon: <MachinesIcon />
          },
          {
            label: 'Inspection Oversight',
            key: '/inspection-oversight',
            icon: <InspectionIcon />
          }
        ]}
      />
      <div className="profile-content-wrapper">
        <div className="profile-text">PROFILE</div>
        <div className="partition">
          <Space size={10} className="profile-content">
            <div className="name-info">
              <Typography.Text>{user?.displayName || 'Admin'}</Typography.Text>
              <Typography.Text className="email-text">
                {user?.email || 'admin@admin.com'}
              </Typography.Text>
            </div>
          </Space>
          <Popconfirm
            title="Logout"
            description="Are you sure to logout from system?"
            onConfirm={async () => {
              await signOutAdmin();
              // eslint-disable-next-line no-restricted-globals, no-undef
              location.href = '/login';
            }}
            okText="Yes"
            cancelText="No"
          >
            <span className="logout-icon">
              <SignOutsIcon />
            </span>
          </Popconfirm>
        </div>
      </div>
    </div>
  );
}
