/* eslint-disable no-console */
import { Button, Form, Input, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoaderComponent from '../../../common/components/LoaderComponent';
import { ROUTES } from '../../../common/constants';
import { sleep } from '../../../common/time';
import { formValidatorRules } from '../../../common/utils';
import { authenticateUser, signIn } from '../auth-repository';
import './login-page.less';

const { required, email: emailValidator } = formValidatorRules;

export function LoginPage({ setUser }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  const onLoginSubmit = async (formValue) => {
    const { email, password } = formValue;
    setLoginLoading(true);
    try {
      const user = await signIn(email, password);
      const { accessToken } = user;
      // eslint-disable-next-line no-undef
      localStorage.setItem('userToken', accessToken);
      setUser(user);
      navigate(ROUTES.MAIN);

      // Do your further actions with the authenticated user here
    } catch (error) {
      setLoginLoading(false);
    }
    setLoginLoading(false);
  };

  useEffect(() => {
    sleep(1000).then(() => {
      authenticateUser().then((user) => {
        if (user === null) {
          setIsLoading(false);
        } else {
          setUser(user);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoading) {
    return <LoaderComponent />;
  }
  return (
    <div className="login-page-content">
      <div className="login-container">
        <Space size={15} align="vertical">
          <span className="login-title">
            <Typography.Title>LOGIN</Typography.Title>
          </span>
          <span className="login-sub-title">
            <Typography.Text>Sign in to your admin account</Typography.Text>
          </span>
        </Space>

        <div className="login-form">
          <Form layout="vertical" onFinish={onLoginSubmit}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[required, emailValidator]}
            >
              <Input type="email" placeholder="Enter your email address" />
            </Form.Item>
            <Form.Item label="Password" name="password" rules={[required]}>
              <Input.Password placeholder="Enter your password" />
            </Form.Item>
            <div className="forgot-pass">
              <Button onClick={() => navigate('/forgot-password')} type="link">
                Forgot password?
              </Button>
            </div>
            <Button
              className="login-btn"
              htmlType="submit"
              loading={loginLoading}
            >
              LOGIN
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
