import { Layout } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import './App.less';
import ContentRoutes from './ContentRoutes';
import { AppSidebar } from './app-sidebar/AppSidebar';

function App({ user }) {
  return (
    <div className="App">
      <Layout>
        <Sider width={240}>
          <AppSidebar user={user} theme="light" />
        </Sider>
        <Content>
          <ContentRoutes user={user} />
        </Content>
      </Layout>
    </div>
  );
}

export default App;
