import dayjs from 'dayjs';
import { REGEX } from './constants';

export const formValidatorRules = {
  required: {
    validator(rule, value) {
      const currentValue = value || '';
      if (currentValue.toString().trim() === '') {
        return Promise.reject(new Error('Required'));
      }
      return Promise.resolve();
    }
  },
  email: {
    type: 'email',
    message: 'The input is not valid E-mail!'
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    }
  }),
  password: {
    validator(rule, value) {
      if (!value) {
        return Promise.reject(new Error('Required'));
      }
      if (value.length < 8) {
        return Promise.reject(
          new Error('Password must be at least 8 characters long')
        );
      }
      return Promise.resolve();
    }
  }
};

export const withHttp = (url) =>
  !/^https?:\/\//i.test(url) ? `http://${url}` : url;

export const getImageData = (data) => {
  if (!data) {
    return [];
  }
  const separatedValue = data.split(',');
  return separatedValue[0];
};

export const clearSearchField = (name) => {
  // eslint-disable-next-line no-undef
  document
    .getElementsByName(name)[0]
    .parentNode.lastChild.lastChild.lastChild.click();
};

export const formatTimestamp = (timestampObj, formatString) => {
  if (!timestampObj) {
    return 'No data';
  }
  // Extract seconds and nanoseconds from the timestamp object
  const { _seconds, _nanoseconds } = timestampObj;

  // Convert to milliseconds (required by dayjs for creating a date)
  const milliseconds = _seconds * 1000 + _nanoseconds / 1000000;

  // Create a dayjs object from milliseconds
  const dateObject = dayjs(milliseconds);

  // Format the date object using the provided formatString
  const formattedDate = dateObject.format(formatString);

  return formattedDate;
};

export const getPaymentStrings = (services) => {
  const connectedServices = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key in services) {
    if (services[key].isConnected) {
      // Capitalize the key before adding it to the array
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      connectedServices.push(capitalizedKey);
    }
  }
  // Join all capitalized keys into a single string
  return connectedServices.join(', ');
};

export const formatFirestoreTimestamp = (timestamp) => {
  if (!timestamp || !timestamp.seconds) return '';
  const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};
