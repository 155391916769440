import { message } from 'antd';
import api from '../../common/api';
import { API_ROUTES } from '../../common/constants';

export const getAdvisors = async (startAfter, limit, query) => {
  let url = `${API_ROUTES.ALL_ADVISORS}?startAfter=&limit=${limit}&search=${query}`;
  if (startAfter) {
    url = `${API_ROUTES.ALL_ADVISORS}?startAfter=${startAfter}&limit=${limit}&search=${query}`;
  }
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: url,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data;
  } catch (error) {
    return null;
  }
};

export const createAdvisor = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'post',
      url: API_ROUTES.CREATE_ADVISOR,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      },
      data: data
    });
    return response?.data?.data?.createdAdvisor;
  } catch (error) {
    throw error?.response?.data?.message;
  }
};

export const updateAdvisor = async (data, ID) => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'put',
      url: `${API_ROUTES.UPDATE_ADVISOR}?advisorId=${ID}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      },
      data: data
    });

    return response?.data?.data?.updatedAdvisorLocation;
  } catch (error) {
    return null;
  }
};

export const getAdvisorById = async (ID) => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: `${API_ROUTES.SINGLE_ADVISOR}?advisorId=${ID}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data?.advisor;
  } catch (error) {
    return null;
  }
};

export const deleteAdvisor = async (ID) => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'delete',
      url: `${API_ROUTES.DELETE_ADVISOR}?advisorId=${ID}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    message.success('Advisor Deleted');
    return response.data;
  } catch (error) {
    message.error('Error while deleting');
    //
  }
};
