import { Button, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  HISTORY_PAGINATION_LIMIT,
  customFormat
} from '../../../common/constants';
import { formatTimestamp } from '../../../common/utils';
import { getLocationHistories } from '../machines-repository';

function LocationHistory({ machineID }) {
  const limit = HISTORY_PAGINATION_LIMIT;

  const [isLoading, setIsLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [isLoadBtn, setIsLoadBtn] = useState(false);
  const [nextAfter, setNextAfter] = useState(null);

  const getHistoryList = async (reset = false) => {
    setIsLoading(true);
    const startAfter = reset ? null : nextAfter;
    try {
      const response = await getLocationHistories(startAfter, limit, machineID);
      const newData = response?.locations || [];

      setHistoryList((prevList) =>
        reset ? [...newData] : [...prevList, ...newData]
      );

      setIsLoadBtn(response?.locations?.length === limit);
      setNextAfter(response?.nextStartAfter);
      setIsLoading(false);
    } catch (error) {
      message.error('Something went wrong');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getHistoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // TABLE DECLARATIONS GOES HERE
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'TO',
      dataIndex: 'to',
      key: '2',
      render: (value) => {
        return value.name;
      }
    },
    {
      title: 'FROM ',
      dataIndex: 'from',
      key: '3',
      render: (value) => {
        return value.name;
      }
    },
    {
      title: 'ON',
      dataIndex: 'updatedAt',
      key: '4',
      render: (value) => {
        const formattedDate = formatTimestamp(value, customFormat);
        return formattedDate;
      }
    }
  ];

  return (
    <div className="content-table">
      <Table
        columns={columns}
        dataSource={historyList}
        loading={isLoading}
        pagination={false}
        rowKey="adminId"
      />
      {isLoadBtn ? (
        <Button
          onClick={() => getHistoryList()}
          className="secondary-btn load-btn"
        >
          Load more
        </Button>
      ) : null}
    </div>
  );
}

export default LocationHistory;
