/* eslint-disable no-console */
import { message } from 'antd';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import api from '../../common/api';
import { API_ROUTES } from '../../common/constants';

const auth = getAuth();

export const authenticateUser = async () => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: API_ROUTES.ME,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data?.user;
  } catch (error) {
    return null;
  }
};

export const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const { user } = userCredential;
    return user;
  } catch (error) {
    // Handle the error or return it to handle in the calling function
    // eslint-disable-next-line no-console
    console.error('Sign-in error:', error.message);
    message.error('Invalid Admin User');
  }
};

export const signOutAdmin = async () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      // An error happened.
    });
  // eslint-disable-next-line no-undef
  localStorage.removeItem('userToken');
};
