/* eslint-disable no-console */
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Tabs,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as BackBtn } from '../../assets/ui-icons/back-btn-icon.svg';
import { FREQUENCY, MACHINE_TYPES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import InspectionHistory from './components/InspectionHistory';
import LocationHistory from './components/LocationHistory';
import {
  getAdvisorsList,
  getLocationList,
  getMachineById,
  updateMachine
} from './machines-repository';
import './machines.less';

function MachineDetails() {
  const { machineID } = useParams();
  const [createForm] = Form.useForm();
  const { required } = formValidatorRules;
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(false);
  const [details, setDetails] = useState([]);
  const [options, setOptions] = useState([]);
  const [advisorOptions, setAdvisorOptions] = useState([]);
  const [isSavingData, setIsSavingData] = useState(false);

  const getMachineDetails = async () => {
    try {
      setLoadingData(true);
      const response = await getMachineById(machineID);
      if (response) {
        setDetails(response.machine);
        createForm.setFieldsValue({
          machineName: response.machine.name,
          machineId: response.machine.machineId,
          machineType: response.machine.type,
          inspector: response.machine.advisor.id,
          inspectionFrequency: response.machine.inspectionFrequency,
          location: response.machine.location.id,
          date: dayjs(response.machine.selectDate)
        });
        setLoadingData(false);
      }
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
    }
  };

  const fetchLocations = async () => {
    const locations = await getLocationList();
    if (locations) {
      const formattedOptions = locations.map((location) => ({
        value: location.id,
        label: location.name
      }));
      setOptions(formattedOptions);
    }
  };

  const fetchAdvisors = async () => {
    const advisors = await getAdvisorsList();
    if (advisors) {
      const formattedOptions = advisors.map((advisor) => ({
        value: advisor.id,
        label: advisor.name
      }));
      setAdvisorOptions(formattedOptions);
    }
  };

  useEffect(() => {
    getMachineDetails();
    fetchLocations();
    fetchAdvisors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledDate = (current) => {
    return current && current < moment().endOf('day');
  };

  const onSubmitSave = async (formValues) => {
    const {
      machineName,
      machineId,
      machineType,
      inspector,
      location,
      inspectionFrequency,
      date
    } = formValues;
    const payload = {
      name: machineName,
      machineId: machineId,
      type: machineType,
      locationId: location,
      inspectionFrequency: inspectionFrequency,
      inspectorId: inspector,
      selectDate: dayjs(date).format('YYYY-MM-DD')
    };
    setIsSavingData(true);
    try {
      const response = await updateMachine(payload, machineID);
      if (response) {
        setIsSavingData(false);
        message.success('Machine updated successfully');
      }
    } catch (error) {
      setIsSavingData(false);
      message.error('Error updating machine');
    }
  };

  return (
    <div className="main-container">
      <Header className="main-header">
        <div className="btn-group">
          <BackBtn className="back-btn" onClick={() => navigate(-1)} />
          <Typography.Title className="header-title">
            MACHINES/{details?.name || 'MACHINE NAME'}
          </Typography.Title>
        </div>
      </Header>
      {loadingData ? (
        <Spin
          size="large"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        />
      ) : (
        <div className="sub-container">
          <div className="details-info-form modal-content">
            <Form
              className="machine-form"
              layout="vertical"
              form={createForm}
              onFinish={onSubmitSave}
            >
              <Row gutter={10}>
                <Col span={6}>
                  <Form.Item
                    rules={[required]}
                    label="Machine name"
                    name="machineName"
                  >
                    <Input type="text" className="modal-inp" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    rules={[required]}
                    label="Machine ID"
                    name="machineId"
                  >
                    <Input
                      disabled
                      type="text"
                      className="modal-inp disabled-inp"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    rules={[required]}
                    label="Machine type"
                    name="machineType"
                  >
                    <Select
                      showSearch
                      placeholder="Select machine type"
                      options={MACHINE_TYPES}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    rules={[required]}
                    label="Inspector "
                    name="inspector"
                  >
                    <Select
                      showSearch
                      placeholder="Select inspector"
                      options={advisorOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item
                    rules={[required]}
                    label="Location "
                    name="location"
                  >
                    <Select showSearch options={options} />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    rules={[required]}
                    label="Inspection Frequency"
                    name="inspectionFrequency"
                  >
                    <Select options={FREQUENCY} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    rules={[required]}
                    label="Select Date "
                    name="date"
                  >
                    <DatePicker disabledDate={disabledDate} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Button
                    loading={isSavingData}
                    htmlType="submit"
                    className="primary-btn"
                    style={{
                      width: '150px'
                    }}
                  >
                    SAVE
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
          <Tabs
            items={[
              {
                key: 'inspectionHistory',
                label: `INSPECTION HISTORY`,
                children: <InspectionHistory machineID={machineID} />
              },
              {
                key: 'locationHistory',
                label: `LOCATION HISTORY`,
                children: <LocationHistory machineID={machineID} />
              }
            ]}
          />
        </div>
      )}

      {/* ALL MODAL GOES HERE */}
      {/* CREATE MODAL */}
    </div>
  );
}

export default MachineDetails;
