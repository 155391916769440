/* eslint-disable no-console */
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Typography,
  Upload,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import axios from 'axios';
import { getType } from 'mime';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../assets/ui-icons/edit-icon.svg';
import { ReactComponent as EyeIcon } from '../../assets/ui-icons/eye-icon.svg';
import { getFileSignedUrl, uploadImage } from '../../common/common-repository';
import SearchComponent from '../../common/components/SearchDebounce';
import { TABLE_PAGINATION_LIMIT, US_STATES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import {
  createLocation,
  getALlLocations,
  updateLocation
} from './locations-repository';

function Locations() {
  // ALL DECLARATIONS GOES HERE
  const { required, number: numberWarning } = formValidatorRules;
  const [createForm] = Form.useForm();
  const limit = TABLE_PAGINATION_LIMIT;
  const navigate = useNavigate();

  // STATES GOES HERE
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [isLoadBtn, setIsLoadBtn] = useState(false);
  const [nextAfter, setNextAfter] = useState(null);
  const [query, setQuery] = useState('');
  const [img, setImg] = useState(undefined);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();

  // ALL METHODS & FUNCTIONS GOES HERE
  const getLocations = async (searchQuery = query, reset = false) => {
    setIsLoading(true);
    const startAfter = reset ? null : nextAfter;
    try {
      const response = await getALlLocations(startAfter, limit, searchQuery);
      const newData = response?.locations || [];

      setLocationList((prevList) =>
        reset ? [...newData] : [...prevList, ...newData]
      );
      setIsLoadBtn(response?.locations?.length === limit);
      setNextAfter(response?.nextStartAfter);
      setIsLoading(false);
    } catch (error) {
      message.error('Something went wrong');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelCreate = () => {
    createForm.resetFields();
    setSelectedId('');
    setCurrentSelectedIndex();
    setImg(undefined);
    setIsCreateModal(false);
    setIsUpdateModal(false);
  };

  const beforeUpload = (file) => {
    const isLT1MB = file.size / 1024 / 1024 <= 1;
    if (!isLT1MB) {
      message.error(`File must smaller than 1MB!`);
      return !isLT1MB && Upload.LIST_IGNORE;
    }
    return false;
  };

  const onIconUpload = async (selectedFile) => {
    const fileName = selectedFile.file.name;
    const fileType = getType(fileName);
    if (fileType === 'image/svg+xml') {
      message.error(`File must be png/jpg/jpeg`);
      return;
    }
    setUploadingImage(true);
    const response = await uploadImage(fileName);
    const { signedUrl, getUrl } = response.data;
    const config = {
      method: 'put',
      url: signedUrl,
      headers: {
        'Content-Type': fileType
      },
      data: selectedFile.file
    };
    await axios(config);
    const url = await getFileSignedUrl(getUrl);
    setImg(url);
    setUploadingImage(false);
  };

  const onSubmitCreateForm = async (formValues) => {
    if (!img) {
      message.error('Verification ID is required');
      return;
    }
    const { locationName, addressLine, state, city, pinCode } = formValues;
    const payload = {
      name: locationName,
      address: addressLine,
      city: city,
      state: state,
      pinCode: pinCode,
      verificationId: img
    };
    setIsSavingData(true);
    try {
      const newLocation = await createLocation(payload);
      setLocationList((prevList) => [...prevList, newLocation]);
      setIsSavingData(false);
      handleCancelCreate();
    } catch (error) {
      //
    } finally {
      setIsSavingData(false);
      handleCancelCreate();
    }
  };

  const locationUpdate = async (index, value, record) => {
    setIsLoading(true);
    setIsUpdateModal(true);
    setTimeout(() => {
      setImg(record.verificationId);
      createForm.setFieldsValue({
        locationName: record.name,
        addressLine: record.address,
        state: record.state,
        city: record.city,
        pinCode: record.pinCode
      });
      setIsCreateModal(true);
      setIsLoading(false);
    }, 500);
  };

  const onSubmitUpdateForm = async (formValues) => {
    if (!img) {
      message.error('Verification ID is required');
      return;
    }
    const { locationName, addressLine, state, city, pinCode } = formValues;
    const payload = {
      name: locationName,
      address: addressLine,
      city: city,
      state: state,
      pinCode: pinCode,
      verificationId: img
    };
    setIsSavingData(true);
    try {
      const updatedLocation = await updateLocation(payload, selectedId);
      const updatedLocationList = locationList.slice();
      updatedLocationList[currentSelectedIndex] = updatedLocation;
      setLocationList(updatedLocationList);
      createForm.resetFields();
      setIsCreateModal(false);
    } catch (error) {
      //
    } finally {
      setIsSavingData(false);
    }
  };

  // const deleteLocationById = async () => {
  //   const key = 'updatable';

  //   try {
  //     message.open({
  //       key,
  //       type: 'loading',
  //       content: 'Deleting location'
  //     });
  //     // Delete admin by ID
  //     await deleteLocation(selectedId);
  //     setLocationList((prevList) =>
  //       prevList.filter((location) => location.id !== selectedId)
  //     );
  //     message.destroy(key);
  //     handleCancelCreate();
  //   } catch (error) {
  //     //
  //   }
  // };

  const pageChange = (ID) => {
    const url = `${ID}`;
    navigate(url);
  };

  // TABLE DECLARATIONS GOES HERE
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'LOCATION NAME',
      dataIndex: 'name',
      key: '2'
    },
    {
      title: 'ADDRESS LINE',
      dataIndex: 'address',
      key: '3'
    },
    {
      title: 'CITY ',
      dataIndex: 'city',
      key: '4'
    },
    {
      title: 'STATE ',
      dataIndex: 'state',
      key: '5'
    },
    {
      title: 'PINCODE ',
      dataIndex: 'pinCode',
      key: '6'
    },
    {
      title: '',
      dataIndex: 'isBlocked',
      key: '7',
      render: (value, record, index) => {
        return (
          <div className="table-col-btn">
            <EditIcon
              className="btns"
              onClick={() => {
                createForm?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateModal(true);
                setSelectedId(record.id);
                setTimeout(() => {
                  locationUpdate(index, value, record);
                }, 200);
              }}
            />
            {/* <BlockIcon className="btns" /> */}
            <EyeIcon className="btns" onClick={() => pageChange(record.id)} />
          </div>
        );
      }
    }
  ];

  return (
    <div className="main-container">
      <Header className="main-header">
        <Typography.Title className="header-title">LOCATIONS</Typography.Title>
        <Button onClick={() => setIsCreateModal(true)}>ADD NEW</Button>
      </Header>

      <div className="sub-container">
        <div className="top-bar">
          <SearchComponent
            name="Locations"
            debounceTime={300} // Consider increasing debounce time to prevent too rapid API calls
            getData={(value) => {
              setQuery(value); // Update search query
              setNextAfter(null); // Reset pagination
              setLocationList([]); // Clear existing list to load new results
              getLocations(value, true); // Pass query and a reset flag to the fetching function
            }}
          />

          {/* <Button className="secondary-btn spacer">
            EXPORT CSV <AiOutlineDownload />
          </Button> */}
        </div>
        <div className="content-table">
          <Table
            rowClassName={(record) =>
              record.isBlocked ? 'disabled-row' : null
            }
            columns={columns}
            dataSource={locationList}
            loading={isLoading}
            pagination={false}
            rowKey="adminId"
          />
          {isLoadBtn ? (
            <Button
              onClick={() => getLocations()}
              className="secondary-btn load-btn"
            >
              Load more
            </Button>
          ) : null}
        </div>
      </div>

      {/* ALL MODAL GOES HERE */}
      {/* CREATE MODAL */}
      <Modal
        title={isUpdateModal ? 'UPDATE LOCATION' : 'NEW LOCATION'}
        open={isCreateModal}
        onCancel={handleCancelCreate}
        footer={null}
        className="admin-modal"
        centered
      >
        <Divider />
        <div className="modal-content">
          <Form
            layout="vertical"
            form={createForm}
            onFinish={isUpdateModal ? onSubmitUpdateForm : onSubmitCreateForm}
          >
            <Form.Item
              rules={[required]}
              label="Location Name"
              name="locationName"
            >
              <Input
                type="text"
                className="modal-inp"
                placeholder="Enter location name"
              />
            </Form.Item>
            <Form.Item
              rules={[required]}
              label="Address Line"
              name="addressLine"
            >
              <Input
                type="text"
                className="modal-inp"
                placeholder="Enter address "
              />
            </Form.Item>
            <Form.Item rules={[required]} label="State" name="state">
              <Select
                showSearch
                placeholder="Select state"
                options={US_STATES}
              />
            </Form.Item>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item rules={[required]} label="City" name="city">
                  <Input
                    type="text"
                    className="modal-inp"
                    placeholder="Enter city "
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[required, numberWarning]}
                  label="Pincode"
                  name="pinCode"
                >
                  <Input
                    type="text"
                    className="modal-inp"
                    placeholder="Enter pincode "
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="modal-div">
              <span>Location ID Proof</span>
              <Upload
                onChange={onIconUpload}
                beforeUpload={beforeUpload}
                showUploadList={false}
                accept="image/*"
                className="upload-img"
              >
                {uploadingImage ? (
                  <Spin size="small" />
                ) : (
                  <span className="upload-span">
                    {img ? (
                      <Image height={30} src={img} alt="verification-id" />
                    ) : (
                      ' Upload Location ID Proof'
                    )}
                  </span>
                )}
              </Upload>
            </div>
            <div className="modal-btns">
              {/* <Button
                onClick={() => deleteLocationById()}
                className="delete-btn"
              >
                DELETE
              </Button> */}
              <Button
                loading={isSavingData}
                className="modal-btn"
                htmlType="submit"
              >
                {isUpdateModal ? '   SAVE' : '     ADD NEW LOCATION'}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default Locations;
