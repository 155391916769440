import { Button, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HISTORY_PAGINATION_LIMIT,
  ROUTES,
  customFormat
} from '../../../common/constants';
import { formatTimestamp } from '../../../common/utils';
import { getInspectionHistory } from '../machines-repository';

function InspectionHistory({ machineID }) {
  const navigate = useNavigate();
  const limit = HISTORY_PAGINATION_LIMIT;

  const [isLoading, setIsLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [isLoadBtn, setIsLoadBtn] = useState(false);
  const [nextAfter, setNextAfter] = useState(null);

  const getHistoryList = async (reset = false) => {
    setIsLoading(true);
    const startAfter = reset ? null : nextAfter;
    try {
      const response = await getInspectionHistory(startAfter, limit, machineID);
      const newData = response?.inspections || [];

      setHistoryList((prevList) =>
        reset ? [...newData] : [...prevList, ...newData]
      );

      setIsLoadBtn(response?.inspections?.length === limit);
      setNextAfter(response?.nextStartAfter);

      setIsLoading(false);
    } catch (error) {
      message.error('Something went wrong');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getHistoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePage = (ID) => {
    navigate(`${ROUTES.INSPECTION_OVERSIGHT}/${ID}`);
  };
  // TABLE DECLARATIONS GOES HERE
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'DATE & TIME',
      dataIndex: 'dueDate',
      key: '2',
      render: (value) => {
        const formattedDate = formatTimestamp(value, customFormat);
        return formattedDate;
      }
    },
    {
      title: 'INSPECTOR ',
      dataIndex: 'advisor',
      key: '3',
      render: (value) => {
        return value?.name;
      }
    },
    {
      title: 'LOCATED AT  ',
      dataIndex: 'location',
      key: '4',
      render: (value) => {
        return value?.name;
      }
    },
    {
      title: 'CHECKLIST POINT  ',
      dataIndex: 'checklistCount',
      key: '5',
      render: (value) => {
        return `${value}/10`;
      }
    },

    {
      title: '',
      dataIndex: 'id',
      key: '7',
      render: (value) => {
        return (
          <div className="table-col-btn">
            <Button
              onClick={() => changePage(value)}
              className="primary-btn"
              style={{
                width: '120px',
                height: '40px'
              }}
            >
              Review
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <div className="content-table">
      <Table
        columns={columns}
        dataSource={historyList}
        loading={isLoading}
        pagination={false}
        rowKey="adminId"
      />
      {isLoadBtn ? (
        <Button
          onClick={() => getHistoryList()}
          className="secondary-btn load-btn"
        >
          Load more
        </Button>
      ) : null}
    </div>
  );
}

export default InspectionHistory;
