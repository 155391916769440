import api from '../../common/api';
import { API_ROUTES } from '../../common/constants';

export const getMachines = async (startAfter, limit, query) => {
  let url = `${API_ROUTES.ALL_MACHINES}?startAfter=&limit=${limit}&search=${query}`;
  if (startAfter) {
    url = `${API_ROUTES.ALL_MACHINES}?startAfter=${startAfter}&limit=${limit}&search=${query}`;
  }
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: url,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data;
  } catch (error) {
    return null;
  }
};

export const getMachineById = async (machineId) => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: `${API_ROUTES.SINGLE_MACHINE}?machineId=${machineId}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data;
  } catch (error) {
    return null;
  }
};

export const getLocationList = async () => {
  const url = `${API_ROUTES.ALL_LOCATIONS}?limit=10000`;

  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: url,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data?.locations;
  } catch (error) {
    return null;
  }
};

export const getAdvisorsList = async () => {
  const url = `${API_ROUTES.ALL_ADVISORS}?limit=10000`;

  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: url,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data?.advisors;
  } catch (error) {
    return null;
  }
};

export const createMachine = async (data) => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'post',
      url: API_ROUTES.CREATE_MACHINE,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      },
      data: data
    });
    return response?.data?.data?.machine;
  } catch (error) {
    return null;
  }
};

export const updateMachine = async (data, ID) => {
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'put',
      url: `${API_ROUTES.UPDATE_MACHINE}?id=${ID}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      },
      data: data
    });
    return response?.data?.data?.updatedMachine;
  } catch (error) {
    return null;
  }
};

export const getInspectionHistory = async (startAfter, limit, ID) => {
  let url = `${API_ROUTES.ALL_INSPECTIONS}?startAfter=&limit=${limit}&machineId=${ID}`;
  if (startAfter) {
    url = `${API_ROUTES.ALL_INSPECTIONS}?startAfter=${startAfter}&limit=${limit}&machineId=${ID}`;
  }
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: url,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data;
  } catch (error) {
    return null;
  }
};

export const getLocationHistories = async (startAfter, limit, ID) => {
  let url = `${API_ROUTES.LOCATION_HISTORIES}?startAfter=&limit=${limit}&machineId=${ID}`;
  if (startAfter) {
    url = `${API_ROUTES.LOCATION_HISTORIES}?startAfter=${startAfter}&limit=${limit}&machineId=${ID}`;
  }
  try {
    // eslint-disable-next-line no-undef
    const userToken = localStorage.getItem('userToken') || '';
    const response = await api({
      method: 'get',
      url: url,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    return response.data?.data;
  } catch (error) {
    return null;
  }
};
