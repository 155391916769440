/* eslint-disable no-console */
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Table,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from '../../assets/ui-icons/edit-icon.svg';
import SearchComponent from '../../common/components/SearchDebounce';
import { formValidatorRules, formatTimestamp } from '../../common/utils';

import { TABLE_PAGINATION_LIMIT, customFormat } from '../../common/constants';
import {
  createAdvisor,
  getAdvisors,
  updateAdvisor
} from './advisors-repository';

function TechnicalAdvisors() {
  // ALL DECLARATIONS GOES HERE
  const {
    required,
    email: emailValidator,
    password: passwordWarning
  } = formValidatorRules;
  const [createForm] = Form.useForm();
  const limit = TABLE_PAGINATION_LIMIT;

  // STATES GOES HERE
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [advisorList, setAdvisorList] = useState([]);
  const [isLoadBtn, setIsLoadBtn] = useState(false);
  const [nextAfter, setNextAfter] = useState(null);
  const [query, setQuery] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState();

  // ALL METHODS & FUNCTIONS GOES HERE
  const getALlAdvisors = async (searchQuery = query, reset = false) => {
    setIsLoading(true);
    const startAfter = reset ? null : nextAfter;
    try {
      const response = await getAdvisors(startAfter, limit, searchQuery);
      const newData = response?.advisors || [];

      setAdvisorList((prevList) =>
        reset ? [...newData] : [...prevList, ...newData]
      );
      setIsLoadBtn(response?.advisors?.length === limit);
      setNextAfter(response?.nextStartAfter);

      setIsLoading(false);
    } catch (error) {
      message.error('Something went wrong');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getALlAdvisors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelCreate = () => {
    createForm.resetFields();
    setSelectedId('');
    setCurrentSelectedIndex();
    setIsCreateModal(false);
    setIsUpdateModal(false);
  };

  const onSubmitCreateForm = async (formValues) => {
    const { advisorName, email, password } = formValues;
    const payload = {
      name: advisorName,
      email: email,
      accessRight: 'ADMIN',
      password: password
    };
    setIsSavingData(true);
    try {
      const newAdvisor = await createAdvisor(payload);
      setAdvisorList((prevList) => [...prevList, newAdvisor]);
      createForm.resetFields();
      setIsCreateModal(false);
    } catch (error) {
      message.error(error);
      setIsSavingData(false);
    } finally {
      setIsSavingData(false);
    }
  };

  const advisorUpdate = async (index, value, record) => {
    setIsLoading(true);
    setIsUpdateModal(true);
    setTimeout(() => {
      createForm.setFieldsValue({
        advisorName: record.name,
        email: record.email
      });
      setIsCreateModal(true);
      setIsLoading(false);
    }, 500);
  };

  const onSubmitUpdateForm = async (formValues) => {
    const { advisorName, email } = formValues;
    const payload = {
      name: advisorName,
      email: email,
      accessRight: 'ADMIN'
    };
    setIsSavingData(true);
    try {
      const updatedAdvisor = await updateAdvisor(payload, selectedId);
      const updatedAdvisorList = advisorList.slice();
      updatedAdvisorList[currentSelectedIndex] = updatedAdvisor;
      setAdvisorList(updatedAdvisorList);
      createForm.resetFields();
      setIsCreateModal(false);
    } catch (error) {
      //
    } finally {
      setIsSavingData(false);
    }
  };

  // const deleteLocationById = async () => {
  //   const key = 'updatable';

  //   try {
  //     message.open({
  //       key,
  //       type: 'loading',
  //       content: 'Deleting location'
  //     });
  //     // Delete admin by ID
  //     await deleteAdvisor(selectedId);
  //     setAdvisorList((prevList) =>
  //       prevList.filter((advisor) => advisor.id !== selectedId)
  //     );
  //     message.destroy(key);
  //     handleCancelCreate();
  //   } catch (error) {
  //     //
  //   }
  // };

  // TABLE DECLARATIONS GOES HERE
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: '2'
    },
    {
      title: 'EMAIL ADDRESS',
      dataIndex: 'email',
      key: '3'
    },
    {
      title: 'ACCOUNT CREATED ',
      dataIndex: 'createdAt',
      key: '4',
      render: (value) => {
        const formattedDate = formatTimestamp(value, customFormat);
        return formattedDate;
      }
    },
    {
      title: 'LAST LOGIN ',
      dataIndex: 'createdAt',
      key: '5',
      render: (value) => {
        const formattedDate = formatTimestamp(value, customFormat);
        return formattedDate;
      }
    },
    {
      title: 'ACCESS RIGHTS ',
      dataIndex: 'accessRight',
      key: '6'
    },
    {
      title: '',
      dataIndex: 'isBlocked',
      key: '7',
      render: (value, record, index) => {
        return (
          <div className="table-col-btn">
            <EditIcon
              className="btns"
              onClick={() => {
                createForm?.resetFields();
                setCurrentSelectedIndex(index);
                setIsUpdateModal(true);
                setSelectedId(record.id);
                setTimeout(() => {
                  advisorUpdate(index, value, record);
                }, 200);
              }}
            />
            {/* <BlockIcon className="btns" /> */}
          </div>
        );
      }
    }
  ];

  return (
    <div className="main-container">
      <Header className="main-header">
        <Typography.Title className="header-title">
          TECHNICAL ADVISORS
        </Typography.Title>
        <Button onClick={() => setIsCreateModal(true)}>ADD NEW</Button>
      </Header>

      <div className="sub-container">
        <div className="top-bar">
          <SearchComponent
            name="Technical Advisors"
            debounceTime={300} // Consider increasing debounce time to prevent too rapid API calls
            getData={(value) => {
              setQuery(value); // Update search query
              setNextAfter(null); // Reset pagination
              setAdvisorList([]); // Clear existing list to load new results
              getALlAdvisors(value, true); // Pass query and a reset flag to the fetching function
            }}
          />
        </div>
        <div className="content-table">
          <Table
            columns={columns}
            dataSource={advisorList}
            loading={isLoading}
            pagination={false}
            rowKey="adminId"
          />
          {isLoadBtn ? (
            <Button
              onClick={() => getALlAdvisors()}
              className="secondary-btn load-btn"
            >
              Load more
            </Button>
          ) : null}
        </div>
      </div>
      {/* ALL MODAL GOES HERE */}
      {/* CREATE MODAL */}
      <Modal
        title={
          isUpdateModal ? 'UPDATE TECHNICAL ADVISOR' : 'NEW TECHNICAL ADVISOR'
        }
        open={isCreateModal}
        onCancel={handleCancelCreate}
        footer={null}
        className="admin-modal"
        centered
      >
        <Divider />
        <div className="modal-content">
          <Form
            layout="vertical"
            form={createForm}
            onFinish={isUpdateModal ? onSubmitUpdateForm : onSubmitCreateForm}
          >
            <Form.Item
              rules={[required]}
              label="Technical Advisor Name"
              name="advisorName"
            >
              <Input
                type="text"
                className="modal-inp"
                placeholder="Enter admin name"
              />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[required, emailValidator]}
            >
              <Input
                className="modal-inp"
                type="email"
                placeholder="Enter email address"
              />
            </Form.Item>
            {isUpdateModal ? null : (
              <Form.Item
                label="Create New Password"
                name="password"
                rules={[passwordWarning]}
              >
                <Input.Password
                  className="modal-inp"
                  placeholder="Enter new password"
                />
              </Form.Item>
            )}
            {isUpdateModal ? null : (
              <Form.Item
                label="Confirm New Password"
                name="confirmPassword"
                dependencies={['password']} // Add dependency on password field to re-validate when password changes
                rules={[
                  required,
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The  passwords that you entered do not match!'
                        )
                      );
                    }
                  })
                ]}
              >
                <Input.Password
                  className="modal-inp"
                  placeholder="Confirm your password"
                />
              </Form.Item>
            )}
            {/* <Form.Item
              rules={[required]}
              label="Access  Rights"
              name="accessRights"
            >
              <Select
                placeholder="Assign Access Rights"
                options={ACCESS_RIGHTS}
              />
            </Form.Item> */}
            <div className="modal-btns">
              {/* <Button
                onClick={() => deleteLocationById()}
                className="delete-btn"
              >
                DELETE
              </Button> */}
              <Button
                loading={isSavingData}
                className="modal-btn"
                htmlType="submit"
              >
                {isUpdateModal ? ' SAVE' : '   ADD NEW TECHNICAL ADVISOR'}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default TechnicalAdvisors;
