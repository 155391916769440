/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-else-return */
/* eslint-disable no-console */
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  QRCode,
  Row,
  Spin,
  Table,
  Tag,
  Typography
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as BackBtn } from '../../assets/ui-icons/back-btn-icon.svg';
import { customFormat } from '../../common/constants';
import { formatTimestamp } from '../../common/utils';
import '../machines/machines.less';
import { getInspectionById } from './inspection-repository';

import './inspection.less';

function InspectionDetails() {
  const [createForm] = Form.useForm();
  const navigate = useNavigate();
  const { inspectionID } = useParams();
  const [view, setView] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [details, setDetails] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [modalDetails, setModalDetails] = useState();

  const getInspectionDetails = async () => {
    try {
      setLoadingData(true);
      const response = await getInspectionById(inspectionID);
      if (response) {
        setDetails(response.inspection);
        createForm.setFieldsValue({
          machineName: response.inspection.machine.name,
          machineId: response.inspection.machine.machineId,
          machineType: response.inspection.machine.type,
          inspector: response.inspection.advisor.name,
          location: response.inspection.location.address,
          inspectionFrequency: response.inspection.machine.inspectionFrequency,
          date: formatTimestamp(response.inspection.dueDate, customFormat)
        });
        setQuestions(response.inspection.questions);
        setLoadingData(false);
      }
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getInspectionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelView = () => {
    setModalDetails(null);
    setView(false);
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = 'QRCode.png';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const tagViewer = (data) => {
    if (data.isSkipable) {
      return (
        <Tag
          style={{
            backgroundColor: '#E5E7EB',
            border: 'none',
            padding: '5px',
            color: '#6B7280'
          }}
        >
          SKIPPED
        </Tag>
      );
    } else if (data.ans === true) {
      return (
        <Tag
          style={{
            backgroundColor: '#059669',
            border: 'none',
            color: 'white',
            padding: '5px 10px 5px 10px'
          }}
        >
          YES
        </Tag>
      );
    } else if (data.ans === false) {
      return (
        <Tag
          style={{
            backgroundColor: '#DC2626',
            border: 'none',
            color: 'white',
            padding: '5px 10px 5px 10px'
          }}
        >
          NO
        </Tag>
      );
    } else {
      return (
        <Tag
          style={{
            backgroundColor: '#F59E0B',
            border: 'none',
            padding: '5px',
            color: '#ffffff'
          }}
        >
          NO FEEDBACK
        </Tag>
      );
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: '1',
      render: (value, record, index) => {
        return index + 1;
      }
    },
    {
      title: 'QUESTION',
      dataIndex: 'question',
      key: '2'
    },
    {
      title: 'FEEDBACK',
      dataIndex: 'ans',
      key: '3',
      render: (value, record) => {
        return tagViewer(record);
      }
    },
    {
      title: '',
      dataIndex: 'imageUrl',
      key: '8',
      render: (value, record) => {
        return (
          <div className="table-col-btn">
            <Button
              disabled={!value}
              onClick={() => {
                setModalDetails(record);
                setTimeout(() => {
                  setView(true);
                }, 200);
              }}
              className="primary-btn"
              style={{
                width: '120px',
                height: '40px'
              }}
            >
              View Photo
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <div className="main-container">
      <Header className="main-header">
        <div className="btn-group">
          <BackBtn className="back-btn" onClick={() => navigate(-1)} />
          <Typography.Title className="header-title">
            INSPECTION OVERSIGHT/{details?.machine?.name || 'MACHINE NAME'}
          </Typography.Title>
        </div>
        <div
          id="myqrcode"
          style={{
            display: 'none'
          }}
        >
          <QRCode value={details?.machine?.machineId} />
        </div>
        <Button
          onClick={downloadQRCode}
          style={{
            backgroundColor: '#F3F4F6',
            color: 'black'
          }}
        >
          DOWNLOAD QR{' '}
        </Button>
      </Header>
      {loadingData ? (
        <Spin
          size="large"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        />
      ) : (
        <div className="sub-container">
          <div className="details-info-form modal-content">
            <Form className="machine-form" layout="vertical" form={createForm}>
              <Row gutter={10}>
                <Col span={6}>
                  <Form.Item label="Machine name" name="machineName">
                    <Input
                      disabled
                      type="text"
                      className="modal-inp disabled-inp"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Machine ID" name="machineId">
                    <Input
                      type="text"
                      disabled
                      className="modal-inp disabled-inp"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Machine type" name="machineType">
                    <Input
                      type="text"
                      disabled
                      className="modal-inp disabled-inp"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Inspector " name="inspector">
                    <Input
                      type="text"
                      disabled
                      className="modal-inp disabled-inp"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item label="Location " name="location">
                    <Input
                      type="text"
                      disabled
                      className="modal-inp disabled-inp"
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    label="Inspection Frequency"
                    name="inspectionFrequency"
                  >
                    <Input
                      type="text"
                      disabled
                      className="modal-inp disabled-inp"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Select Date " name="date">
                    <Input
                      type="text"
                      disabled
                      className="modal-inp disabled-inp"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <span className="note-span">NOTE : {details?.note}</span>
            <span
              style={{
                paddingTop: '10px',
                fontWeight: '800'
              }}
            >
              CHECKLIST :
            </span>
            <div className="content-table">
              <Table
                columns={columns}
                dataSource={questions}
                pagination={false}
                rowKey="adminId"
              />
            </div>
          </div>
        </div>
      )}

      {/* ALL MODAL GOES HERE */}
      {/* VIEW MODAL */}
      <Modal
        title="PHOTO PREVIEW"
        open={view}
        onCancel={handleCancelView}
        footer={null}
        className="admin-modal"
        centered
      >
        <Divider />
        <div className="photo-content">
          <span>{modalDetails?.question}</span>
          <span>
            {modalDetails?.isSkipable ? (
              <Tag
                style={{
                  backgroundColor: '#E5E7EB',
                  border: 'none',
                  padding: '5px',
                  color: '#6B7280'
                }}
              >
                SKIPPED
              </Tag>
            ) : modalDetails?.ans ? (
              <Tag
                style={{
                  backgroundColor: '#059669',
                  border: 'none',
                  color: 'white',
                  padding: '5px 10px 5px 10px'
                }}
              >
                YES
              </Tag>
            ) : (
              <Tag
                style={{
                  backgroundColor: '#DC2626',
                  border: 'none',
                  color: 'white',
                  padding: '5px 10px 5px 10px'
                }}
              >
                NO
              </Tag>
            )}
          </span>
          <Image src={modalDetails?.imageUrl} height={350} alt="Image" />
        </div>
      </Modal>
    </div>
  );
}

export default InspectionDetails;
