import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import App from './app/App';
import LoaderComponent from './common/components/LoaderComponent';
import { ROUTES } from './common/constants';
import { authenticateUser } from './modules/auth/auth-repository';
import { ForgotPage } from './modules/auth/forgot-password/ForgotPage';
import { LoginPage } from './modules/auth/login/LoginPage';

function AppRoutes() {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setTimeout(async () => {
      authenticateUser().then((result) => {
        setUser(result);
        setIsLoading(false);
      });
    }, 800);
  }, []);

  if (isLoading) {
    return <LoaderComponent />;
  }
  return (
    <Routes>
      <Route
        path={ROUTES.LOGIN}
        element={
          <PublicRoutes>
            <LoginPage setUser={setUser} />
          </PublicRoutes>
        }
      />
      <Route
        path={ROUTES.FORGOT_PASSWORD}
        element={
          <PublicRoutes user={user}>
            <ForgotPage />
          </PublicRoutes>
        }
      />
      <Route
        path="/*"
        element={
          <PrivateRoute user={user}>
            <App user={user} setUser={setUser} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
export default AppRoutes;
